import { ADDSELLERS_ADMIN_ROUTES } from '@/constants/routing'

export const BONUS_PROGRAM_LEVELS = {
  FIRST_LEVEL: 'Стартовый уровень',
  SECOND_LEVEL: 'Бронзовый уровень',
  THIRD_LEVEL: 'Серебрянный уровень',
  FOURTH_LEVEL: 'Золотой уровень',
  FIFTH_LEVEL: 'Платиновый уровень',
  SIXTH_LEVEL: 'Брилиантовый уровень'
}

export const BONUS_PROGRAM_STATUSES = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  ARCHIVED: 'ARCHIVED'
}

export const BONUS_PROGRAM_STATUSES_DISPLAY = {
  ACTIVE: 'Активная',
  INACTIVE: 'Не активная',
  ARCHIVED: 'Архивная'
}

export const BONUS_PROGRAM_PLACES = {
  FIRST_PLACE: '1-ое место',
  SECOND_PLACE: '2-ое место',
  THIRD_PLACE: '3-ее место'
}

export const BONUS_PROGRAM_ROUTES = {
  [BONUS_PROGRAM_STATUSES.ARCHIVED]:
    ADDSELLERS_ADMIN_ROUTES.BONUS_PROGRAM.ARCHIVE.INDEX,
  [BONUS_PROGRAM_STATUSES.ACTIVE]:
    ADDSELLERS_ADMIN_ROUTES.BONUS_PROGRAM.ACTIVE.INDEX,
  [BONUS_PROGRAM_STATUSES.INACTIVE]:
    ADDSELLERS_ADMIN_ROUTES.BONUS_PROGRAM.UPDATE
}
