<template>
  <div :class="$style.page">
    <div :class="$style.buttons">
      <adw-button
        v-if="form.status === $options.BONUS_PROGRAM_STATUSES.INACTIVE"
        color="primary"
        @click="submitForm"
      >
        Сохранить
      </adw-button>
      <adw-button
        v-if="form.status === $options.BONUS_PROGRAM_STATUSES.INACTIVE"
        color="primary"
        @click="submitFormAndReturn"
      >
        Сохранить и вернуться
      </adw-button>
      <adw-button
        v-if="form.status === $options.BONUS_PROGRAM_STATUSES.INACTIVE"
        color="primary"
        @click="startBonusProgram"
        >Запустить спринт</adw-button
      >
    </div>
    <el-form
      :class="$style.form"
      :model="form"
      :rules="rules"
      ref="form"
      label-position="right"
      label-width="12.5rem"
    >
      <el-form-item label="Название" prop="name">
        <el-input
          v-model="form.name"
          placeholder="Введите название"
          :readonly="isDisabled"
        ></el-input>
      </el-form-item>
      <el-form-item label="Описание" prop="description">
        <el-input
          v-model="form.description"
          placeholder="Введите описание"
          :readonly="isDisabled"
        ></el-input>
      </el-form-item>
      <el-form-item label="Статус" prop="status">
        <el-input
          :value="getBonusProgramStatus(form.status)"
          :readonly="isDisabled"
        ></el-input>
      </el-form-item>
      <el-form-item label="Бренды участники" prop="brandIDs">
        <el-select
          v-model="form.brandIDs"
          multiple
          filterable
          clearable
          default-first-option
          placeholder="Выберите бренды"
          :disabled="isDisabled"
          :class="$style.select"
        >
          <el-option
            v-for="item in brands"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Дата начала" prop="startsAt">
        <AdwCalendar
          id="orderDatePicker"
          :value="form.startsAt"
          :disabledDates="disabledDays"
          minimum-view="day"
          maximum-view="day"
          placeholder="Выберите дату"
          is-hide-icon
          :disabled="isDisabled"
          @selected="form.startsAt = $event"
        />
      </el-form-item>
      <el-form-item label="Дата окончания" prop="endsAt">
        <AdwCalendar
          id="orderDatePicker"
          :value="form.endsAt"
          :disabledDates="disabledDays"
          minimum-view="day"
          maximum-view="day"
          placeholder="Выберите дату"
          is-hide-icon
          :disabled="isDisabled"
          @selected="form.endsAt = $event"
        />
      </el-form-item>
      <div
        v-for="(level, index) of form.levels"
        :key="index"
        :class="$style.inputsWrapper"
      >
        <div :class="$style.inputs">
          <el-form-item
            :label="getLevelLabel(level.name)"
            :prop="'levels.' + index + '.achieveAmount'"
            :rules="rules.levels.achieveAmount"
          >
            <el-input
              v-model="form.levels[index].achieveAmount"
              type="number"
              placeholder="Введите сумму до следующего уровня"
              min="0"
              :readonly="index === 0 || isDisabled"
              :class="$style.input"
            ></el-input>
          </el-form-item>
          <el-form-item
            :prop="'levels.' + index + '.cashbackSize'"
            :rules="rules.levels.cashBackSize"
          >
            <el-input
              v-model="form.levels[index].cashbackSize"
              type="number"
              placeholder="Введите кешбэк в %"
              min="0"
              :readonly="index === 0 || isDisabled"
              :class="$style.input"
            ></el-input
          ></el-form-item>
        </div>
        <template v-if="index === 0">
          <div :class="$style.prizes">
            <span :class="$style.prizesText"
              >Введите призы для трех первых мест в стартовом уровне</span
            >
            <div
              v-for="(prize, index) of form.levels[0]?.prizes"
              :key="index"
              :class="$style.prize"
            >
              <el-form-item
                :label="`Для ${prize.place} места`"
                :prop="'levels.' + 0 + '.prizes.' + index + '.prize'"
                :rules="rules.prizes.prize"
              >
                <el-input
                  v-model="form.levels[0].prizes[index].prize"
                  type="number"
                  placeholder="Введите размер приза"
                  min="0"
                  :readonly="isDisabled"
                  :class="$style.input"
                ></el-input>
              </el-form-item>
            </div>
          </div>
        </template>
      </div>
    </el-form>
  </div>
</template>

<script>
import delivery from '@/delivery'
import notifications from '@/mixins/notifications'

import { ADDSELLERS_ADMIN_ROUTES } from '@/constants/routing'
import { getStringEnding } from '@/helpers/index'
import {
  BONUS_PROGRAM_LEVELS,
  BONUS_PROGRAM_STATUSES,
  BONUS_PROGRAM_STATUSES_DISPLAY,
} from '@/constants/bonusProgram'

export default {
  BONUS_PROGRAM_LEVELS,
  BONUS_PROGRAM_STATUSES,
  BONUS_PROGRAM_STATUSES_DISPLAY,
  components: {},
  ADDSELLERS_ADMIN_ROUTES,
  getStringEnding,
  mixins: [notifications],
  data() {
    return {
      form: {
        name: '',
        description: '',
        brandIDs: [],
        startsAt: null,
        endsAt: null,
        levels: [],
      },
      limit: 100000,
      brands: [],
      day: 24 * 60 * 60 * 1000,
      rules: {
        name: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur',
          },
        ],
        description: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur',
          },
        ],
        brandIDs: [
          {
            type: 'array',
            required: true,
            message: 'Пожалуйста, выберите хоть один бренд',
            trigger: 'blur',
          },
        ],
        startsAt: [
          {
            required: true,
            message: 'Пожалуйста, выберите дату начала',
            trigger: 'blur',
          },
        ],
        endsAt: [
          {
            required: true,
            trigger: 'blur',
            message: 'Пожалуйста, выберите дату начала',
          },
          {
            trigger: 'blur',
            validator: this.validateEndDate,
          },
        ],
        levels: {
          type: 'array',
          required: true,
          achieveAmount: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
            {
              trigger: 'blur',
              validator: this.validateAchieveAmount,
            },
          ],
        },
        prizes: {
          type: 'array',
          required: true,
          prize: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
        },
      },
    }
  },
  computed: {
    disabledDays() {
      return { to: new Date(Date.now() - this.day) }
    },
    isDisabled() {
      return this.form.status !== BONUS_PROGRAM_STATUSES.INACTIVE
    },
  },
  async created() {
    Promise.all([
      await this.getBonusProgram(),
      await this.getBrandsList(),
      await this.getActivesBrandsList(),
    ])
  },
  methods: {
    async submitForm() {
      const valid = await new Promise((resolve) => {
        this.$refs.form.validate((valid) => resolve(valid))
      })
      if (valid) {
        const loading = this.$loading({
          lock: true,
        })

        if (this.form.levels[0]) {
          this.form.levels[0].prizes = this.form?.levels[0]?.prizes.map(
            (item) => ({
              place: item.place ?? '',
              prize: Number(item.prize) ?? 0,
            }),
          )
        }

        this.form.levels = this.form.levels.map((item) => ({
          achieveAmount: Number(item.achieveAmount) ?? 0,
          cashbackSize: Number(item.cashbackSize) ?? 0,
          prizes: item.prizes,
        }))

        const { error } = await delivery.AddwineCore.BonusProgramActions.update(
          this.$route.params.id,
          {
            ...this.form,
          },
        )

        loading.close()
        if (!error) {
          this.showNotification(
            'Бонусная программа успешно отредактирована',
            'success',
          )
          return true
        }
      }

      return false
    },
    async submitFormAndReturn() {
      const isSuccessSubmit = await this.submitForm()
      if (isSuccessSubmit) {
        this.$router.go(-1)
      }
    },
    async getBrandsList() {
      const { value, error } = await delivery.AddwineCore.BrandsActions.getList(
        {
          limit: this.limit,
        },
      )

      if (error) return

      this.brands = value.data
    },
    validateEndDate(_, value, callback) {
      if (this.form?.startsAt > this.form?.endsAt) {
        callback(
          new Error('Дата окончания не может быть раньше, чем дата начала'),
        )
      } else {
        callback()
      }
    },
    validateAchieveAmount(rule, value, callback) {
      let hasError = false

      for (let i = 0; i <= this.form?.levels?.length - 1; i++) {
        if (!i || !this.form?.levels[i]?.achieveAmount) {
          continue
        }
        if (
          Number(this.form.levels[i - 1].achieveAmount) >=
            Number(this.form.levels[i].achieveAmount) &&
          rule.field === 'levels.' + i + '.achieveAmount'
        ) {
          hasError = true
          callback(
            new Error(
              'Сумма следующего уровня не может быть меньше или равна сумме предыдущей',
            ),
          )

          continue
        }
      }

      if (!hasError) {
        callback()
      }
    },
    async getBonusProgram() {
      const loading = this.$loading({
        lock: true,
      })

      const { value, error } =
        await delivery.AddwineCore.BonusProgramActions.getById(
          this.$route.params.id,
        )

      loading.close()

      if (error) return

      this.form = {
        ...value,
        startsAt: new Date(value.startsAt),
        endsAt: new Date(value.endsAt),
        brandIDs: [...this.form.brandIDs],
      }
    },
    async getActivesBrandsList() {
      const { value, error } = await delivery.AddwineCore.BrandsActions.getList(
        {
          limit: this.limit,
          bonusProgramId: this.$route.params.id,
        },
      )

      if (error) return

      this.form.brandIDs = value.data.map((item) => item.id)
    },
    getLevelLabel(level) {
      return BONUS_PROGRAM_LEVELS[level]
    },
    getStringEnding(number, labelsList) {
      return getStringEnding(number, labelsList)
    },
    async startBonusProgram() {
      const { error } = await delivery.AddwineCore.BonusProgramActions.start(
        this.$route.params.id,
      )

      if (error?.includes('BRANDS INCLUDED IN ANOTHER ACTIVE PROGRAMS')) {
        const brands = error?.split(':')[2]?.split(',')
        this.showNotification(
          `${this.getStringEnding(brands.length, [
            `Бренд ${brands} уже участвует`,
            `Бренды ${brands} уже участвуют`,
            `Бренды ${brands} уже участвуют`,
          ])} в активной бонусной программе`,
          'error',
        )
        return
      }

      if (error) {
        this.showNotification('Ошибка запуска бонусной программы', 'error')
        return
      }

      this.showNotification('Бонусная программа успешно запущена', 'success')
      this.$router.push(ADDSELLERS_ADMIN_ROUTES.BONUS_PROGRAM.LIST)
    },
    getBonusProgramStatus(status) {
      return BONUS_PROGRAM_STATUSES_DISPLAY[status] || ''
    },
  },
}
</script>

<style lang="scss" module>
.page {
  .buttons {
    @include stickyWrapper;
    gap: 1.5rem;
    margin-bottom: 1rem;
  }
  padding: 1rem;
  .form {
    & > div > label {
      text-align: left;
    }

    .inputsWrapper {
      display: flex;
      flex-direction: column;
      margin-bottom: 1rem;

      .prizes {
        display: flex;
        flex-direction: column;

        .prizesText {
          @include H200;
          margin-bottom: 1rem;
        }

        .prize {
          display: flex;
          margin-bottom: 1rem;

          &:last-child {
            margin-bottom: 0;
          }

          & > div > label {
            text-align: left;
          }

          .input {
            display: flex;
            width: 20rem;
          }
        }
      }

      .inputs {
        display: flex;
        & > div:last-child > div {
          margin-left: 3rem !important;
        }

        & > div > label {
          text-align: left;
        }

        .input {
          display: flex;
          width: 20rem;
        }
      }
    }

    .select {
      width: 100%;
    }
  }
}
</style>
